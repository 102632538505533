import * as React from 'react';

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<any, any, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something has gone wrong!</h1>;
    }

    return this.props.children;
  }
}
